<template>
  <div class="pt-10">
    <vx-card v-if="teacherDetails" class="mb-base">
      <div class="vx-breadcrumb md:block hidden">
        <ul class="flex flex-wrap items-center">
          <li class="inline-flex items-center">
            <router-link
              :to="{ name: 'center-admin-teachers-list' }"
            >Teachers
            </router-link>
            <span class="breadcrumb-separator mx-2">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right"
                >
                  <polyline points="13 17 18 12 13 7"/>
                </svg>
              </span>
            </span>
          </li>

          <li class="inline-flex items-center">
            <a href="javascript:void(0)" class>{{ teacherDetails.fullName }}</a>
          </li>
        </ul>
      </div>

      <div class="vx-col w-full mb-base">
        <div class>
          <div class="flex items-center my-5">
            <h2 class="font-bold" style="color: black">{{ teacherDetails.fullName }}</h2>
            <div class="ml-auto">
              <vs-button class="mr-4" @click.stop="editTeacher( teacherDetails._id)">Edit</vs-button>
              <vs-button
                class="mr-4"
                color="danger"
                @click.stop="deleteTeacher( teacherDetails._id)"
              >Delete
              </vs-button>
            </div>
          </div>

          <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
              <div class="w-full mb-5">
                <div class="img-container w-64">
                  <img
                    :src="(teacherDetails.profileImage && teacherDetails.profileImage != 'test.jop') ? teacherDetails.profileImage : $defaultImage"
                    alt="img"
                    class="responsive"
                  />
                </div>
              </div>
            </vs-col>

            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6" class="mt-8">
              <div class="w-full mb-5">
                <label class="font-semibold text-dark">Room</label>
                <p
                  class="text-dark"
                >{{ teacherDetails.roomDetail ? teacherDetails.roomDetail[0] : '' }}</p>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
              <div class="w-full mb-5">
                <label class="font-semibold text-dark">Title</label>
                <p
                  class="text-dark"
                >{{ "undefined" != typeof teacherDetails.title ? teacherDetails.title.replace('.', '') : '' }}</p>
              </div>
            </vs-col>

            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full mb-5">
                <label class="font-semibold text-dark">Full Name</label>
                <p class="text-dark">{{ teacherDetails.fullName }}</p>
              </div>
            </vs-col>

            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full mb-5">
                <label class="font-semibold text-dark">Mobile Number</label>
                <p class="text-dark">{{ teacherDetails.mobileNumber }}</p>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full mb-5">
                <label class="font-semibold text-dark">Email</label>
                <p class="text-dark">{{ teacherDetails.email }}</p>
              </div>
            </vs-col>

            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full mb-5">
                <label class="font-semibold text-dark">Street Address</label>
                <p
                  class="text-dark"
                >{{ "undefined" != typeof teacherDetails.address ? teacherDetails.address.street : '' }}</p>
              </div>
            </vs-col>

            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full mb-5">
                <label class="font-semibold text-dark">Town/City</label>
                <p
                  class="text-dark"
                >{{ "undefined" != typeof teacherDetails.address ? teacherDetails.address.suburb : '' }}</p>
              </div>
            </vs-col>

            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full mb-5">
                <label class="font-semibold text-dark">State</label>
                <p
                  class="text-dark"
                >{{ "undefined" != typeof teacherDetails.address ? teacherDetails.address.state : '' }}</p>
              </div>
            </vs-col>

            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full mb-5">
                <label class="font-semibold text-dark">Post Code</label>
                <p
                  class="text-dark"
                >{{ "undefined" != typeof teacherDetails.address ? teacherDetails.address.postCode : '' }}</p>
              </div>
            </vs-col>
            <vs-col class="flex w-full md:w-1/2">
              <div class="w-full mb-5">
                <label class="font-semibold text-dark">Account Status</label>
                <p>

                  <vs-button v-if="teacherDetails.accountStatus"
                             class="mr-4 mt-1"
                             :color="teacherDetails.accountStatus =='Active' ? 'success' :'danger'"
                  >{{ teacherDetails.accountStatus }}
                  </vs-button>
                </p>

              </div>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </vx-card>
  </div>
</template>
<script>
import {mapActions} from "vuex";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  data() {
    return {
      teacherDetails: {},
    };
  },
  watch: {},
  computed: {
    centerAdmin() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    ...mapActions("centerAdmin", [
      "getCenterAdminTeacherDetails",
      "deleteCenterAdminTeacher",

    ]),
    ...mapActions("center", [
      "getDirectorAllCountries",
    ]),
    async getDetails(id) {
      this.$vs.loading();
      await this.getCenterAdminTeacherDetails(id)
        .then(async (res) => {
          this.teacherDetails = await res.data.data;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          //console.error(err)
        });
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color,
      });
    },
    editTeacher(id) {
      this.$router.push({name: "center-admin-teachers-edit", params: {id: id}});
    },
    deleteTeacher(id) {
      Swal.fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.acceptDeleteAlert(id);
        }
      });
    },
    acceptDeleteAlert(id) {
      this.$vs.loading();
      this.deleteCenterAdminTeacher(id)
        .then((res) => {
          if (res.status === 200) {
            this.$vs.loading.close();
            this.showMessage(
              "Success",
              "Teacher deleted successfully.",
              "success"
            );
          }
          this.$router.push({name: "center-admin-teachers-list"}).catch(() => {
          });
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },
  },

  created() {
    let id = this.$route.params.id;

    this.getDetails(id);
  },
};
</script>
